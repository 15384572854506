import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This was used as the default demonstration conversation for years. `}</p>
    <ul>
      <li parentName="ul">{`Open data: `}<a parentName="li" {...{
          "href": "https://github.com/compdemocracy/openData/tree/master/15-per-hour-seattle"
        }}>{`https://github.com/compdemocracy/openData/tree/master/15-per-hour-seattle`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      